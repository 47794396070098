( function( $ ){

    $( document ).ready( function() {


        /*
         * Mobile Menu
         */

        $('.js-menuToggle').on('click', function(e){
            e.preventDefault();
            $('.js-menuToggle').toggleClass('burger--open');
            $('.nav-primary__menu').toggleClass('nav-primary__menu--open  animated  fadeInDown');
            $('#js-header').toggleClass('site-head--open');
        });

        function closeMenu() {
            $('.js-menuToggle').removeClass('burger--open');
            $('#js-header').removeClass('site-head--open');
            $('.nav-primary__menu').removeClass('nav-primary__menu--open  animated  fadeInDown');
        }

        $(window).resize(function(){
            closeMenu();
        });



        /*
         * Sticky Header on scroll
         */
        var headerInverted = false,
            header = $('#js-header'),
            headStickOn = 200;

        if (header.hasClass('site-head--inverted')) {
            headerInverted = true;
        }

        window.addEventListener('scroll', function(e){
            var distanceY = window.pageYOffset || document.documentElement.scrollTop;

            if (distanceY > headStickOn) {
                header.addClass('site-head--sticky  animated  slideInDown');
                header.removeClass('site-head--inverted');
            } else {
                if (header.hasClass('site-head--sticky')) {
                    header.removeClass('site-head--sticky  animated  slideInDown');
                }
                if (headerInverted) {
                    header.addClass('site-head--inverted');
                }
            }
        });


        /*
         * Sticky Page Nav
         */
        if($('#js-page-nav').length) {
            var headerStickyHeight = 50,
            pageNav = $('#js-page-nav');
            var bannerHeight = $('#js-banner').outerHeight();
            if(bannerHeight < 101) { bannerHeight = 250; }

            var pageNavStickOn = bannerHeight - headerStickyHeight;

            window.addEventListener('scroll', function(e){
                var distanceY = window.pageYOffset || document.documentElement.scrollTop;

                if (distanceY > pageNavStickOn) {
                    pageNav.addClass('page-nav--fixed  animated  fadeIn');
                } else {
                    if (pageNav.hasClass('page-nav--fixed')) {
                        pageNav.removeClass('page-nav--fixed  animated  fadeIn');
                    }
                }
            });
        }


        /*
         * Back to top arrow
         */
        window.addEventListener('scroll', function(e){
            var distanceY = window.pageYOffset || document.documentElement.scrollTop;

            if (distanceY > $('#js-banner').outerHeight()) {
                $('.anchor-page-top').addClass('show');
            } else {
                $('.anchor-page-top').removeClass('show');
            }
        });


        /*
         * Accordions
         */
        $('.js-accordion-toggle').on('click', function(e){
            e.preventDefault();

            $(this).parent().find('.js-accordion-reveal').toggleClass('hidden');
            $(this).find('.fa').toggleClass('icon-animate--rotate');

            $('html,body').animate({
                scrollTop: $(this).parent().offset().top - 230
            }, 100);
        });



        /*
         * Page Slider
         * @desc Settings via data-attributes
         */

        $('.js-slick').slick();



        /*
         * Smooth Scroll
         * https://css-tricks.com/snippets/jquery/smooth-scrolling/
         */
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 120
                    }, 1000);
                    return false;
                }
            }
        });

        var urlHash = window.location.href.split("#")[1];
        if (urlHash &&  $('#' + urlHash).length ) {
            $('html,body').animate({
                scrollTop: $('#' + urlHash).offset().top - 230
            }, 1000);

            //open accordion
            $('#' + urlHash).find('.js-accordion-reveal').toggleClass('hidden');
            $('#' + urlHash).find('.fa').toggleClass('icon-animate--rotate');
        }



        /*
         * Equal height panels
         */
        var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = [],
        $el,
        topPosition = 0;

        function equalHeights() {

            $('.panel').each(function() {

                $el = $(this);
                topPosition = $el.position().top;

                if (currentRowStart != topPosition) {

                    // we just came to a new row.  Set all the heights on the completed row
                    for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
                        rowDivs[currentDiv].height(currentTallest);
                    }

                    // set the variables for the new row
                    rowDivs.length = 0; // empty the array
                    currentRowStart = topPosition;
                    currentTallest = $el.height();
                    rowDivs.push($el);

                } else {

                    // another div on the current row.  Add it to the list and check if it's taller
                    rowDivs.push($el);
                    currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);

                }

                // do the last row
                for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
                    rowDivs[currentDiv].height(currentTallest);
                }

            });

        }
        equalHeights();

        $(window).resize(function(){
            $('.panel').css({'height':'auto'});
            setTimeout(equalHeights, 1500);
        });

    });

})( jQuery );